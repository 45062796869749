export const errorMessage = {
    DEFAULT: `ไม่สามารถจ่ายงานได้ โปรดติดต่อคนขับและทำรายการอีกครั้ง'`,
    ORDER_STATUS_INCORRECT: `
    <div class="d-flex flex-column items-center justify-center px-6">
        <div class="font-bold">ไม่สามารถเพิ่มงานได้ เนื่องจาก:</div>
        <div class="d-flex justify-center">
            <ol class="text-left my-2">
                <li>ออเดอร์ถูกยกเลิกแล้ว</li>
                <li>ไรเดอร์มีงานจากสาขาอื่นอยู่</li>
                <li>ควบออเดอร์เกิน 3 งาน (จำกัดสูงสุด 3 งาน/คน)</li>
                <li>มีคนขับรับงานไปแล้ว</li>
            </ol>
        </div>
        <div class="font-bold mt-4">วิธีแก้ไข:</div>
        <div class="d-flex justify-center">
            <ol class="text-left mt-2">
                <li>หลังจากระบบรีเฟรชหน้า Driver MGT แล้วให้ตรวจสอบสถานะล่าสุด</li>
                <li>ตรวจสอบตำแหน่งคนขับบน 1112D MGT (ดูวิธีใช้ใน Manual)</li>
            </ol>
        </div>
    </div>
    `,
    INCORRECT_POSITION: `
    <div class="d-flex flex-column items-center justify-center px-6">
        <div class="d-flex justify-center">
            <ol class="text-left">
                <li>ตรวจสอบว่าโปรไฟล์คนขับอยู่จังหวัดเดียวกับร้าน</li>
                <li>เช็คในแอปว่าพื้นที่รับงานตรงกับสาขาหรือไม่</li>
                <li>หากข้อมูลผิดพลาด ติดต่อ<br />คุณยูถิกา (Yuthika_su@minor.com) เพื่อแก้ไข</li>
            </ol>
        </div>
    </div>
    `,
    MORE_TABLE_TIME: `
    <div class="d-flex flex-column items-center justify-center px-6">
        <div class="d-flex justify-center">
            <ol class="text-left">
                <li>เช็คว่าคนขับอยู่นอกโซนรับงานหรือไม่</li>
                <li>ตรวจสอบตำแหน่งผ่าน 1112D MGT หรือ ตารางงาน</li>
                <li>หากผิดพลาด ให้คนขับรอในโซนหรือปรับตารางงาน</li>
            </ol>
        </div>
    </div>
    `,
    CURRENT_DRIVER_UNABLE: `
    <div class="d-flex flex-column items-center justify-center px-6">
        <div class="font-bold">ไม่สามารถจ่ายงานได้ เนื่องจากไม่พบสัญญาณคนขับ:</div>
        <div class="d-flex justify-center mt-2">
            <ol class="text-left">
                <li>ยังไม่เปิดแอปออนไลน์</li>
                <li>สัญญาณออฟไลน์ ให้ตรวจสอบแอปและอินเทอร์เน็ต</li>
            </ol>
        </div>
    </div>
    `,
    NOT_ALLOWED_TO_RECEIVE_ORDER: `
    <div class="d-flex flex-column items-center justify-center px-6">
        <div class="font-bold">ไม่สามารถจ่ายงานได้:</div>
        <div class="d-flex justify-center mt-2">
            <ol class="text-left">
                <li>ร้านไม่ได้ set area ไว้</li>
                <li>คนขับมีตารางงาน PZ ร้าน A อยู่<br />แต่จะแอดงาน PZ ร้าน B ให้</li>
            </ol>
        </div>
    </div>
    `,
    USER_HAS_CANCELLED_THE_ORDER: `มีคนขับรับงาน หรือออเดอร์ถูกยกเลิกแล้ว<br />กรุณาเช็คสถานะออเดอร์อีกครั้ง`,
    BACKLOG_HAS_BEEN_PROCESSED: `หากสาขามีคนขับที่มีตารางงาน และว่างงานอยู่<br />จะไม่สามารถแอดงานให้กับคนขับนอกตารางงานได้`,
    CUSTOMER_SERVICE_ASSIGNMENT_FAILED: `
    <div class="d-flex flex-column items-center justify-center px-6">
        <div >ไม่สามารถจ่ายงานให้ไรเดอร์ได้ สาขากำลัง Assign งานให้ไรเดอร์ในขณะที่ไรเดอร์ได้รับงานจากระบบ</div>
        <div class="font-bold mt-4">วิธีแก้ไข:</div>
        <div class="d-flex justify-center">
            <ol class="text-left mt-2">
                <li>หลังจากระบบรีเฟรชหน้า Driver MGT แล้ว ให้ตรวจสอบสถานะล่าสุด</li>
                <li>ตรวจสอบตำแหน่งคนขับบน 1112D MGT (ดูวิธีใช้ใน Manual)</li>
            </ol>
        </div>
    </div>
    `,
}